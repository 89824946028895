@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    padding: 0;
    margin: 0;
  }

  html,
  body {
    height: 100%;
    color: #00050f;
    background-color: #f7f7f8;
  }

  body {
    font-family: "Open Sans", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
  }

  img,
  picture,
  video,
  canvas,
  svg {
    display: block;
    max-width: 100%;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }

  #root {
    isolation: isolate;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/OpenSans-Bold.woff2") format("woff2"),
      url("./assets/fonts/OpenSans-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/OpenSans-ExtraBold.woff2") format("woff2"),
      url("./assets/fonts/OpenSans-ExtraBold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/OpenSans-BoldItalic.woff2") format("woff2"),
      url("./assets/fonts/OpenSans-BoldItalic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/OpenSans-ExtraBoldItalic.woff2") format("woff2"),
      url("./assets/fonts/OpenSans-ExtraBoldItalic.woff") format("woff");
    font-weight: 800;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/OpenSans-Medium.woff2") format("woff2"),
      url("./assets/fonts/OpenSans-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/OpenSans-Light.woff2") format("woff2"),
      url("./assets/fonts/OpenSans-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/OpenSans-LightItalic.woff2") format("woff2"),
      url("./assets/fonts/OpenSans-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/OpenSans-MediumItalic.woff2") format("woff2"),
      url("./assets/fonts/OpenSans-MediumItalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/OpenSans-Italic.woff2") format("woff2"),
      url("./assets/fonts/OpenSans-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/OpenSans-SemiBoldItalic.woff2") format("woff2"),
      url("./assets/fonts/OpenSans-SemiBoldItalic.woff") format("woff");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/OpenSans-SemiBold.woff2") format("woff2"),
      url("./assets/fonts/OpenSans-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Open Sans";
    src: url("./assets/fonts/OpenSans-Regular.woff2") format("woff2"),
      url("./assets/fonts/OpenSans-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
}

@layer components {
  .otp-input {
    @apply bg-gray-light rounded-md w-60 h-12 focus:outline-primary-300 text-center;
  }

  .btn {
    @apply text-base p-5 text-black appearance-none inline-flex justify-center font-semibold items-center rounded-lg focus:outline-none focus:ring-gray-100 focus:ring-2 transition-colors duration-200;
  }

  .btn.btn-primary {
    @apply bg-primary focus:ring-primary-200 focus:ring-4 text-white;
  }

  .btn-outline {
    @apply border;
  }

  .btn-danger {
    @apply bg-error text-white;
  }
}

.custom-grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}
.custom-grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}
.custom-grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
.custom-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.custom-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.input-group {
  position: relative;
}

.input-group > div {
  display: flex;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input-group .input {
  --tw-border-opacity: 1;
  background-color: transparent;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  border-radius: 8px;
  border-width: 1px;
  display: block;
  flex-grow: 1;
  font-size: 1rem;
  height: 56px;
  line-height: 1.333rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
}
